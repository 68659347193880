

.testimonial .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.testimonial .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 500px;
  height:  auto;
}

.testimonial .swiper-slide img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .testimonial .swiper-slide {
    width: 300px;
    height: auto;
  }
    
}
